import axios from "axios";
import Utility from "../../../shared/utility";
import { store } from "../../../state-management/state-mgmt";
export default {
  //Project Data
  getProject(call, userId) {
    return this.getData(`/cp/project_header?pass=1&user_id=${userId}`, call);
  },
  //Country Data
  countryList(call) {
    return this.getData("/cp/country_state_details", call);
  },

  //Country data new API
  countryListNew(userId, countryKey, pass, call) {
    return this.getData(`/cp/country_state_details_new?user_id=${userId}&country_key=${countryKey}&pass=${pass}`, call);
  },
  //Country State Master API
  countryStateMaster(call, obj) {
    return this.getData("/co/country_state_master", call, obj);
  },
  //Business Data
  getBusiness(call, userId) {
    return this.getData(`/cp/business_header?Pass=1&UserId=${userId}`, call);
  },
  postBusinessData(call, obj) {
    return this.getData("/co/business_getbyId", call, obj);
  },
  //Business and Project combination data
  getBusinessOnProject(call, userId) {
    return this.getData(`ap/role_businesslist?User_Id=${userId}`, call);
  },
  //Carrier List data
  getCarrierList(call, userID) {
    let include = true;
    return this.getData(`/cr/carrier_list?UserId=${userID}&IncludeAll=${include}`, call);
  },
  //To get "ALL" in project list for all projects -1
  getProjectAll(call, userId) {
    return this.getData(`cp/project_header?pass=3&user_id=${userId}`, call);
  },
  //To get "ALL" in project list for all projects 0
  getProjectAllComp(call, userId) {
    return this.getData(`cp/project_header?pass=4&user_id=${userId}`, call);
  },
  getProjectForBusiness(call, userId) {
    return this.getData(`cp/project_header?pass=5&user_id=${userId}`, call);
  },
  //Get Next Available LPN
  getNextAvailableLPN(call, userId) {
    return this.getData(`co/common_next_available_lpn?user_key=${userId}`, call);
  },
  userPrinterPreferences(call, obj, showMessage) {
    return this.getData("/co/user_printer_preference", call, obj, showMessage);
  },
  // Issue Tracker
  postIssueTracker(call, obj, showMessage) {
    return this.getData(`/co/issue_tracker`, call, obj, showMessage);
  },
  // Issue Tracker
  postIssueImages(call, issue_id, obj, showMessage) {
    return this.getData(`/co/upload_issue_files?issue_id=${issue_id}`, call, obj, showMessage);
  },

  async getData(url, call, obj, showMessage) {
    let LoaderDialog = {
      visible: true,
      title: "Please Wait...",
    };
    store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    let res = {};
    if (call == "get") {
      res = await axios.get(url).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    } else {
      res = await axios.post(url, obj).catch(function (error) {
        if (error.response) {
          let Alert = {
            type: "error",
            isShow: true,
            message: error.response.data.message,
          };
          store.commit("ConfigModule/Alert", Alert);
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        }
      });
    }
    if (res !== undefined) {
      if (res.data.body == null) {
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return JSON.parse('{"message":"NA"}');
      }
      if (res.data.body.message != undefined || res.data.body.message != null) {
        if (!res?.data?.body?.message) {
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(res.data.body.message),
          };
          store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = res.data.body.message;
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          return {};
        }
        try {
          if (showMessage) {
            let Alert = {
              type: "success",
              isShow: true,
              message: res.data.message,
            };
            store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          if (Utility.isJson(res?.data?.body?.message)) return JSON.parse(res?.data?.body?.message);
          else return res?.data?.body?.message;
        } catch (err) {
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(err),
          };
          store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = err.message;
          return {};
        }
      } else if (res.data.body.message == null) {
        LoaderDialog.visible = false;
        store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        return JSON.parse('{"message":"NA"}');
      } else {
        if (!res?.data?.message) {
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(res.data.message),
          };
          store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = res.data.message;
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          return {};
        }
        try {
          if (showMessage) {
            let Alert = {
              type: "success",
              isShow: true,
              message: res.data.message,
            };
            store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          return JSON.parse(res?.data?.message);
        } catch (err) {
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(err),
          };
          store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = err.message;
          return {};
        }
      }
    }
  },
};
