<template>
  <div>
    <v-row class="px-10 pt-3 pb-5">
      <v-col v-for="(item) in arrayData" :key="item.id" :lg=colNum sm="3" md="3" >
        <v-template class="ma-0 pa-0 secondary--text">{{item.label}}</v-template> <br />
          <div class="ma-0 pa-0 f-bold f-12" v-html="formatedValues(item.value)"></div>
    </v-col>
  </v-row>
  </div>
</template>
<script>
import Utility from "../../shared/utility";
export default {
  name: 'exportLimit',
  props: {
    arrayData: Array,
    colNum:Number
  },

  data() {
    return {

    }
  },
  mounted(){
  },
  methods: {
    formatedValues(value){
    return Utility.insertLineBreaks(value,1,"|")}
  },
  
}
</script>
  